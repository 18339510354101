import Sprite from '../sprites/sprite';

interface SocialsProps {
  fill?: string;
}

export const FACEBOOK = 'https://www.facebook.com/kumbhsukrit';
export const INSTAGRAM = 'https://www.instagram.com/kumbh_2025_/';
export const TWITTER = 'https://x.com/kumbhsukrit';
export const YOUTUBE = 'https://www.youtube.com/@KumbhSukrit';

// eslint-disable-next-line no-empty-pattern
export const Socials = ({}: SocialsProps) => {
  return (
    <>
      <a href={FACEBOOK} target="_blank" rel="noreferrer">
        <li className="cursor-pointer hover:opacity-75 transition">
          <Sprite
            name="facebook"
            width="64"
            height="64"
            className="min-w-6 w-6 min-h-6 h-6"
          />
        </li>
      </a>
      <a href={INSTAGRAM} target="_blank" rel="noreferrer">
        <li className="cursor-pointer hover:opacity-75 transition">
          <img
            src="/instagram.png"
            alt="Instagram logo"
            className="min-w-6 w-6 min-h-6 h-6"
          />
        </li>
      </a>
      <a href={TWITTER} target="_blank" rel="noreferrer">
        <li className="cursor-pointer hover:opacity-75 transition">
          <Sprite
            name="twitter"
            width="64"
            height="64"
            className="min-w-6 w-6 min-h-6 h-6"
          />
        </li>
      </a>
      <a href={YOUTUBE} target="_blank" rel="noreferrer">
        <li className="cursor-pointer hover:opacity-75 transition">
          <Sprite
            name="youtube"
            width="64"
            height="45"
            className="min-w-6 w-6 min-h-6 h-6"
          />
        </li>
      </a>
    </>
  );
};
